import ProjectCard from 'components/ProjectCard'
import React, { useEffect, useState } from 'react'
import Overview from './overview';
import Task from './task';
import Members from './members';
import { Drawer } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { GETCLIENTPROJECTS } from 'state/actions';
import { useNavigate } from 'react-router-dom';

function Dashboard() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const className = "bg-tertiary rounded-3xl"
  const [tab, setTab] = useState("overview");
  const [open, setOpen] = useState(false);

  const { selProject } = useSelector(state => state.user);


  const createTask = () => {
    navigate("/new-task");
    // setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  // const projectDetails = {
  //   projectName: "Coca-Cola Design",
  //   updatedOn: "04 Aug, 2024",
  //   assignedTo: [
  //     {
  //       name: "Kunal",
  //       url: "1"
  //     },
  //     {
  //       name: "Jatin",
  //       url: "1"
  //     },
  //     {
  //       name: "Sumit",
  //       url: "1"
  //     }
  //   ]
  // }

  const setSelectedTab = (tabName) => () => {
    if (tab != tabName) {
      setTab(tabName)
    }
  }

  useEffect(() => {
    dispatch(GETCLIENTPROJECTS())
  },[])

  return (
    <React.Fragment>
      <div className='flex flex-col w-full bg-secondary rounded-lg'>
        <ProjectCard className="px-7 py-6" details={selProject} createTask={createTask} />
        <div className='border-t border-tertiary flex space-x-6 px-5 py-2 text-sm items-center'>
          <div className={ (tab == "overview" && className) + ' cursor-pointer px-3 py-2 ' } onClick={setSelectedTab("overview")}>Overview</div>
          <div className={ (tab == "task" && className) + ' cursor-pointer px-3 py-2 ' } onClick={setSelectedTab("task")} >Task</div>
          <div className={ (tab == "members" && className) + ' cursor-pointer px-3 py-2 '} onClick={setSelectedTab("members")}>Members</div>
        </div>
      </div>
      {
        tab == "overview" && (
          <Overview />
        )
      }
      {
        tab == "task" && (
          <Task />
        )
      }
      {
        tab == "members" && (
          <Members />
        )
      }
      <Drawer className='!bg-primary text-card font-primary' title="Create Task" onClose={onClose} open={open}>
        Task Form
      </Drawer>
    </React.Fragment>
  )
}

export default Dashboard
