import { AntDesignOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Tooltip } from 'antd';
import DropDown from 'components/Dropdown';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

function ProjectCard({ details, className = "", createTask }) {
  
  const {projectRespondents} = useSelector(state => state.task);
  const projectList = [
    { value: 'coca-cola-design', label: 'Coca-Cola Design' },
    // { value: 'pepsi', label: 'Pepsi' }
  ]
  // const [ projects, setProjects ] = useState([projectList]);

  return (
    <div className={`flex w-full justify-between ${className}`}>
      <div className='flex flex-col space-y-4'>
        <div className='flex items-center space-x-3'>
          {
            projectList.length > 1 ?
              <DropDown className="project-sel font-primary !text-xl text-primary font-bold" options={projectList} defaultValue="Coca-Cola Design" />
              : <div className='font-primary text-xl text-primary font-bold'>{details?.project_name}</div>
          }
          <div className='text-secondary text-xs'>Last updated on  {moment(details.updatedAt).format("ll")} </div>
        </div>
        <div className='flex items-center space-x-2'>
          <UserOutlined className='text-secondary' />
          <div className='text-sm'>Assigned to Project</div>
          <Avatar.Group
            size="default"
          >
            {
              !!projectRespondents.length && projectRespondents.map((assignedUser, index) =>
              (
                <React.Fragment>
                  <Tooltip title={assignedUser.full_name} placement="top">
                    <Avatar className='bg-secondary border-2 ' src={"https://api.dicebear.com/7.x/miniavs/svg?seed=" + index} />
                  </Tooltip>
                </React.Fragment>
              )

              )
            }
          </Avatar.Group>
          {/* <Avatar.Group>
            <Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=1" />
            <Avatar style={{ backgroundColor: '#f56a00' }}>K</Avatar>
            <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
            <Avatar style={{ backgroundColor: '#1677ff' }} icon={<AntDesignOutlined />} />
          </Avatar.Group> */}
        </div>
      </div>
      <div className='space-x-5 '>
        {/* <Button className='font-secondary bg-secondary text-primary hover:!bg-secondary hover:!text-theme hover:!border-theme' icon={<PlusOutlined />}>Assign Respondent</Button> */}
        <Button className='font-secondary bg-theme text-primary border-theme hover:!bg-theme  hover:!text-primary' icon={<PlusOutlined />} onClick={createTask} >Create Task</Button>
      </div>
    </div>
  )
}

export default ProjectCard;
