import CustomTable from 'components/Table'
import Toolbar from 'components/Toolbar'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

function Members() {

  const {projectRespondents} = useSelector(state => state.task);
  const [userData, setUserData] = useState([]);

  useEffect(()=>{
    setUserData(projectRespondents)
  },[projectRespondents])

  return (
    <React.Fragment>
      <Toolbar isStatus />
      <CustomTable tableData={userData} />
    </React.Fragment>
  )
}

export default Members
