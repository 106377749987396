import AWS from 'aws-sdk';

export const uploadFile = async (file, path, fileName) => {
    // const REGION = "region";

    AWS.config.update({
      accessKeyId:  "AKIAYMFUIGZFJ34HUFYK",
      secretAccessKey: "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",
    });
    const s3 = new AWS.S3({
      params: { Bucket: "dekko-superadmin" },
      region: 'ap-south-1',
    });

    const fileExtension = file.name.split(".")[1]

    const params = {
      Bucket: "dekko-superadmin",
      Key:  path + "/" + fileName + "." + fileExtension ,
      Body: file,
    };

    var upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        console.log(
          "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
        );
      })
      .promise();

    await upload.then((err, data) => {
      console.log(err);
      console.log("File uploaded successfully. -->", data);
      console.log("File uploaded successfully.-->", err);
      return data
    });
  };


  export default { uploadFile }