import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from 'state/store';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: 'var(--secondary-font)',
            color: 'var(--font-primary)',
            backgroundColor: 'var(--bg-primary)',
          },
          components: {
            Input: {
              colorTextPlaceholder: "var(--font-secondary)"
            },
            DatePicker: {
              colorTextPlaceholder: "var(--font-primary)",
            },
            Select: {
              colorTextPlaceholder: "var(--font-primary)",
            },
            Breadcrumb: {
              colorTextPlaceholder: "var(--font-primary)",
            },
            Table: {
              backgroundColor: 'var(--bg-primary)',
            },
          }
        }}
      >
        <App />
      </ConfigProvider>
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
