import Card from 'components/Cards'
import Toolbar from 'components/Toolbar'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GETPROJECTTASKS } from 'state/actions';

function Task() {
  
  const dispatch = useDispatch();
  const { selProject } = useSelector(state => state.user)
  const { tasks } = useSelector(state => state.task)
  const [taskSet, setTaskSet] = useState([]);

  useEffect(() => {
    if (selProject?.uuid) {
      dispatch(GETPROJECTTASKS(selProject.uuid))
    }
  },[selProject])

  useEffect(() => {
    setTaskSet(tasks);
  },[tasks])
  
  // const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

  return (
    <React.Fragment>
      <Toolbar  isSortStatus isDate />
      <div className='flex py-2 flex-wrap space-y-3 items-baseline'>
        {
          taskSet.map((card, index) =>
            <Card details={card} />
          )
        }
      </div>
    </React.Fragment>
  )
}

export default Task
