import React from 'react'
import TextInput from '../components/TextInput/TextInput'
import PrePages from '../layouts/prePages'
import { Button, Checkbox, Form, Input } from 'antd'
import TermAndCondition from 'components/TermAndCondition';

function ForgetPassword() {

  const onFinish = (values) => {
    console.log('Success:', values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <PrePages>
      <Form name="basic"
        style={{ width: 430 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        size="large"
        layout="vertical"
      >
        <div className='text-2xl font-medium font-primary text-left mb-4 '>Forget Password</div>
        <TextInput
          label="Email"
          name="email"
          placeholder="Email address"
          autoComplete="off"
          rules={[
            {
              required: true,
              message: 'Please input your email!',
            },
          ]}
        />

        <Form.Item>
          <Button className='w-full' type="primary" htmlType="submit">
            Send Code
          </Button>
        </Form.Item>
        <TermAndCondition />
      </Form>
    </PrePages>
  )
}

export default ForgetPassword
