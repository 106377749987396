import Header from "layouts/header";
import SideMenu from "layouts/menu";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { getCookie } from "utils/session";

function PrivateRoute({ component, onTrigger }) {
    const { pathname } = useLocation();
    const [tab, setTab] = useState("");
    const { isLoggedIn, userauth } = useSelector(state => state.user);

    const authToken = getCookie("_u");

    useEffect(() => {
        if (pathname !== "") {
            let tabName = pathname.replace("/", "")
            tabName = tabName.split("/")[0];
            // console.log("tabName", tabName.split("/")[0]);
            // console.log("pathname", pathname);
            setTab(tabName)
        }
    }, [pathname])

    const navigate = useNavigate();

    // const navigateToPage = (tabName) => {
    //     setTab(tabName);
    //     switch (tabName) {
    //         case "buy":
    //             break;
    //         case "wallet":
    //             navigate("/add-wallet");
    //             break;
    //         case "markets":
    //             navigate("/market");
    //             break;
    //         case "home":
    //             navigate("/home");
    //             break;
    //         case "share":
    //             navigate("/share");
    //             break;
    //         case "chat":
    //             navigate("/chat");
    //             break;
    //         case "settings":
    //             navigate("/settings");
    //             break;
    //     }
    // };


    return (
        <React.Fragment>
            {!!authToken ? (
                <React.Fragment>
                    <Header />
                    <div className="flex w-full h-[calc(100vh-80px)]">
                        {/* <SideMenu /> */}
                        <div className="p-10 w-full overflow-auto">
                            {React.cloneElement(component, { selTab: tab })}
                        </div>
                    </div>
                </React.Fragment>
            ) : (
                <Navigate to={"/"} />
            )}
        </React.Fragment>
    );
}

export default PrivateRoute;
