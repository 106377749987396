import { BellOutlined } from '@ant-design/icons'
import { Avatar, Badge } from 'antd'
import React from 'react'

function Notification() {
    return (
        <Badge className='cursor-pointer' dot style={{ background: 'var(--bg-active)', borderColor: 'var(--bg-active)'  }}>
            <Avatar shape="circle" icon={<BellOutlined />} />
        </Badge>
    )
}

export default Notification
