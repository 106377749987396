import React from 'react'

function PopoverInfo({ content }) {
    return (
        <div className='flex relative pl-4'>
            <div className='info-arrow'></div>
            <div className='info-box'>
                {content}
            </div>

        </div>
    )
}

export default PopoverInfo
