import { combineReducers } from "redux";
import userReducer from "./user.reducer";
import taskReducer from "./task.reducer";

const appReducer = combineReducers({
  user: userReducer,
  task: taskReducer,
});

const rootReducer = (state, action) => {
//   if (action.type === "USER_LOGOUT") {
//     const { routing } = state;
//     state = { routing };
//     return appReducer(state, action)
//   }

  return appReducer(state, action);
};

export default rootReducer;
