import { CalendarOutlined, FlagOutlined, MessageOutlined, PaperClipOutlined } from '@ant-design/icons'
import { Avatar } from 'antd';
import React from 'react'

function Card({ details, index = 1 }) {
    const today = new Date();
    const users = [1, 2, 3, 4, 5, 6];

    return (
        <div className='flex rounded-2xl border border-tertiary bg-secondary w-[470px] h-[200px] mr-2'>
            <div className='p-6 text-left space-y-3 w-full'>
                <div className='text-card text-base font-semibold'>
                    {details?.title}
                </div>
                <div className='text-secondary text-xs font-semibold overflow-hidden truncate w-[300px]'>
                    {details?.description}
                </div>
                <div className='flex items-center justify-between pt-3 pb-2'>
                    <div className='text-xs text-secondary'><FlagOutlined style= {{ color : 'var(--text-secondary)' }} /> &nbsp; {new Date(details?.due_date).toDateString()} </div>
                    <Avatar.Group
                        size="small"
                        max={{
                            count: 4,
                            style: { color: 'var(--font-primary)', backgroundColor: 'var(--bg-tertiary)', fontSize: '12px' },
                        }}
                    >
                        {
                            users.map((user) => (
                                <Avatar className='bg-secondary border-2 ' src={"https://api.dicebear.com/7.x/miniavs/svg?seed=" + index} />
                            ))
                        }
                    </Avatar.Group>
                </div>
                <div className='border-t border-tertiary py-3 text-xs text-secondary justify-between flex items-center'>
                    <div className='flex items-center space-x-4'>
                        <div className=''>
                            <PaperClipOutlined style= {{ color : 'var(--text-secondary)' }} /> 2
                        </div>
                        <div>
                            <MessageOutlined style= {{ color : 'var(--text-secondary)' }} /> 7
                        </div>
                    </div>
                    <div className='flex items-center'>
                        <CalendarOutlined style= {{ color : 'var(--text-secondary)' }} />
                        &nbsp;
                        {new Date(details?.start_date).toDateString()}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Card
