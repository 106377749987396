import React from 'react'

function Image(props) {
    const { url, alt = "Dekko", className = "", wrapperclassName = "" } = props;
    return (
        <div className={`flex justify-center items-center ${ wrapperclassName }`}>
            <img
                src={require(`assets/images/${url}`)}
                alt={alt}
                onError={(e) =>
                    (e.target.onerror = null)(
                        (e.target.src =
                            "https://picsum.photos/200/300?grayscale")
                    )
                }
                className={className}
            />
        </div>
    )
}

export default Image
