import React from "react";
import { Form, Select, Space } from "antd";

import FloatInput from "../FloatInput";


const MobileInput = (props) => {

    return (
        <React.Fragment>
            <Form.Item name={props.name} rules={props.rules} hasFeedback>
                <FloatInput
                    {...props}
                />
            </Form.Item>
        </React.Fragment>
    );
};

export default MobileInput;
