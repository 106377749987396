import MediaCard from 'components/Cards/mediaCard'
import Toolbar from 'components/Toolbar';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GETPROJECTOVERVIEW, GETPROJECTRESPONDENTS } from 'state/actions';

function Overview() {
  // const overviewList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const dispatch = useDispatch();
  const { selProject } = useSelector(state => state.user)
  const { overviewResponse, projectRespondents } = useSelector(state => state.task)
  const [overviewSet, setOverviewSet] = useState([]);

  useEffect(() => {
    if (selProject?.uuid) {
      const payload = {
        project_uuid : selProject.uuid,
        page : 1,
        pageSize : 10
      }
      dispatch(GETPROJECTOVERVIEW(payload))
      dispatch(GETPROJECTRESPONDENTS({project_uuid : selProject.uuid }))
    }
  },[selProject])

  useEffect(() => {
    setOverviewSet(overviewResponse);
  },[overviewResponse])

  useEffect(() => {
    console.log("projectRespondents", projectRespondents);
  },[projectRespondents])

  return (
    <React.Fragment>
      <Toolbar isDate isKeyword isRating isRespondent isStatus />
      <div className='py-3 flex items-baseline space-y-4 flex-wrap space-x-4'>
        {
          overviewSet.map((item, index) => (
            <MediaCard key={item + "_" + index} details={item} />
          ))
        }
      </div>
    </React.Fragment>
  )
}

export default Overview
