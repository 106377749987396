import React from "react";
import { Form } from "antd";

import FloatInput from "../FloatInput";

import "./index.css";


const TextInput = (props) => {
  return (
    <React.Fragment>
      <Form.Item name="email" rules={props.rules} hasFeedback>
        <FloatInput
          {...props}
        />
      </Form.Item>
    </React.Fragment>
  );
};

export default TextInput;
