import React, { useEffect } from 'react'
import PrePages from '../layouts/prePages'
import { Button, Form } from 'antd'
import { useNavigate } from 'react-router-dom'
import TermAndCondition from 'components/TermAndCondition'
import MobileInput from 'components/MobileNumber'
import DropDown from 'components/Dropdown'
import { useDispatch, useSelector } from 'react-redux'
import { LOGINBYMOBILE, SETUSERDATA } from 'state/actions'


const options = [
  {
    value: '+91',
    label: '+91',
  },
  {
    value: '+1',
    label: '+1',
  },
];


function Signup() {

  const { userOTPPayload } = useSelector(state => state.user)
  const [form] = Form.useForm();
  form.setFields([{ name: "code" }])
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const onSubmit = (values) => {
    const val = form.getFieldValue("code");
    const payload = {
      ...values,
      code: val
    }

    dispatch(SETUSERDATA(payload))
    dispatch(LOGINBYMOBILE(payload))
  }

  const onFinish = (values) => {
    // console.log("form before", form.getFieldsValue());
    // navigate("/dashboard")
    onSubmit(values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };


  const onInputChange = (input, value) => {
    const { value: inputValue } = input.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
      // console.log("inputValue", inputValue);
    }
  }

  const onCodeChange = (value) => {
    form.setFieldsValue({
      code: value,
    });
  }

  useEffect(() => {

    // console.log("form before", form.getFieldValue("code"));
    form.setFieldsValue({
      code: "+91",
    });
    // console.log("form after", form.getFieldValue("code"));
  }, [form])

  useEffect(() => {
    if (!!userOTPPayload.sid) {
      navigate("/verify-otp")
    }
  }, [userOTPPayload])

  const selectBefore = (
    <DropDown defaultValue="+91" options={options} name="code" size="large" onChange={onCodeChange} className="!w-20 !h-full border-none " />
  )


  return (
    <PrePages>
      <Form name="basic"
        style={{ width: 430 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        // autoComplete="off"
        autoComplete="new-state"
        size="large"
        layout="vertical"
      >
        <div className='text-2xl font-medium text-left mb-4 font-primary'>Sign In</div>
        <MobileInput
          addonBefore={selectBefore}
          label="Mobile Number"
          name="mobile"
          placeholder="Mobile Number"
          autoComplete="off"
          className="w-full"
          labelCustomClass="!left-[20%] z-10"
          onChange={onInputChange}
          rules={[
            {
              required: true,
              message: 'Please input your mobile number!',
            },
          ]}
        />

        <Form.Item>
          <Button className='w-full' type="primary" htmlType="submit">
            Get OTP
          </Button>
        </Form.Item>
        <TermAndCondition />
      </Form>
    </PrePages>
  )
}

export default Signup
