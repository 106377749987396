import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import { message } from 'antd';
import { uploadFile } from 'utils/s3Upload';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';


function UploadBox(props) {


    const { selProject } = useSelector(state => state.user);

    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const [fileObj, setFileObj] = useState(null);
    const [fileName, setFileName] = useState("");

    const handleChange = (info) => {
            setLoading(true);
            const path = selProject.project_name+"_" + selProject.uuid; 
            const { file, fileList } = info;
            if (fileList.length) {
                const fName = uuidv4();
                const fileExtension = file.name.split(".")[1]
                uploadFile(fileList[0].originFileObj, path, fName)
                .then((res) => {
                    storeFilePath( path, fName, fileList[0].originFileObj)
                    setLoading(false);
                    props.handleChange(fName + "." + fileExtension, res )
                })    
            } else {
                setImageUrl("");
                setFileName("");
                setLoading(false);
            }
    };

    const storeFilePath = (path, fname, file) =>{
        const fileExtension = file.name.split(".")[1]
        const directoryName = "https://dekko-superadmin.s3.ap-south-1.amazonaws.com"
        setImageUrl(directoryName + "/" +path + "/" + fname + "." + fileExtension)
        setFileName(fname + "." + fileExtension)
    }

    const beforeUpload = (file) => {

        // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        // if (!isJpgOrPng) {
        //     message.error('You can only upload JPG/PNG file!');
        // }
        const isLt20M = file.size / 1024 / 1024 < 20;
        if (!isLt20M) {
            message.error('Image must smaller than 10MB!');
        }

        
        // return isJpgOrPng && isLt2M;
        // return isLt20M;
        return false;;
    };

    const uploadButton = (
        <button
            className='w-full bg-none border-none'
            type="button"
        >
            {loading ? <LoadingOutlined /> : ""}
            <div className='text-white'>
                Upload video
            </div>
        </button>
    );

    return (
        <div>
            <Upload
                accept="video/mp4,video/x-m4v,video/*"
                name="avatar"
                listType="picture-card"
                multiple={ false }
                className={"avatar-uploader !text-white "+ props.className}
                // showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={handleChange}
            >
                {!!imageUrl ? (
                    <video muted autoPlay className="!h-full" >
                         <source src={imageUrl}  type="video/mp4"></source>
                    </video>
                ) : (
                    uploadButton
                )}
            </Upload>
        </div>
    )
}

export default UploadBox
