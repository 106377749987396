import { CameraFilled, FileImageFilled, VideoCameraFilled } from '@ant-design/icons';
import { Button, Checkbox, DatePicker, Popover } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import PathTrail from 'components/BreadCrum'
// import CustomDatePicker from 'components/DatePicker';
import DropDown from 'components/Dropdown';
import PopoverInfo from 'components/popoverInfo';
import TextInputNormal from 'components/TextInputNormal';
import UploadBox from 'components/Upload';
import UploadTile from 'components/Upload/tile';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CREATEPROJECTSTASK, GETCLIENTPROJECTS, TASKADDEDSUCCESS } from 'state/actions';


const masterMedia = {
    Video : {
        defaultOption: "cameraVideo",
        value: "cameraVideo",
        isLandscapevideo: true,
        options: [
            {
                label: "Front or Back Camera",
                value: "cameraVideo",
            },
            {
                label: "Back Camera",
                value: "cameraVideoBack",
            },
            {
                label: "Front Camera",
                value: "cameraVideoFront",
            }
        ]
    },
    Gallery : {
        defaultOption: "galleryPhoto,galleryVideo",
        value: "gallery",
        isLandscapevideo: true,
        options: [
            {
                label: "Photo or Video any",
                value: "gallery",
            },
            {
                label: "Only Photo",
                value: "galleryPhoto",
            },
            {
                label: "Only Video",
                value: "galleryPhoto",
            }
        ]
    },
     Photo : {
        defaultOption: "cameraPhoto",
        value: "cameraPhoto",
        isLandscapevideo: true,
        options: [
            {
                label: "Front or Back Camera",
                value: "cameraPhoto",
            },
            {
                label: "Back Camera",
                value: "cameraPhotoBack",
            },
            {
                label: "Front Camera",
                value: "cameraPhotoFront",
            }
        ]
    }
    
    
}


// const Recording = {
//     defaultOption: "screen_recording_back_camera",
//     value: "screen_recording_back_camera",
//     options: [
//         {
//             label: "Screen recording with Back Camera",
//             value: "screen_recording_back_camera",
//         },
//         {
//             label: "Screen recording with Front Camera",
//             value: "screen_recording_front_camera",
//         }
//     ]
// }


function CreateTask() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { selProject, createTaskSuccess } = useSelector(state => state.user);

    const [masterMediaOptions, setMasterMediaOptions,] = useState(masterMedia);
    const [payload, setPayload,] = useState({
        "title": null,
        "start_date": null,
        "due_date":null,
        "description":null,
        "media_type":null,
        "project_uuid":null,
        "sample_media_link": null
    });
    const [selMedia, setSelMedia] = useState([]);
    const [isExpand, setIsExpand] = useState(false);

    const trail = [
        {
            title: 'New Task',
            href: '',
        },
    ];

    const onChangeHandle = (fileName, status) => {
        setPayload({ ...payload, sample_media_link : fileName })

    }

    const onChange = (e) => {
        const {value, name} = e.target;
        setPayload({ ...payload, [name] : value })
    }

    const onSubmit = (e) => {
        let payloadData = {};
        const values = [];
        selMedia.map(media => {
            values.push(masterMediaOptions[media].value);
        })
        payloadData = {...payload, media_type : values.join(","), project_uuid : selProject.uuid};

        dispatch(CREATEPROJECTSTASK(payloadData))
    }

    const onMediaTypeSelection = (value, isChecked, name, skip = false) => {
        if (isChecked) {
            setIsExpand(name);
        } else{
            setIsExpand(isChecked);
        }

        if (!skip) {
            if (selMedia.includes(name)) {
                setSelMedia(media => media.filter((med, i) => med !== name))
            } else {
                setSelMedia([...selMedia,name]);
            }    
        }


        const options = masterMediaOptions[name];
        options.value = value;
        setMasterMediaOptions({...masterMediaOptions, [name] : options})
    }

    useEffect(() => {
        if (createTaskSuccess) {
            navigate("/dashboard")
            dispatch(TASKADDEDSUCCESS(false))
        }
    },[createTaskSuccess])


    const handleOptionChange = (value, isTrue, name) => {
        onMediaTypeSelection(value, isTrue, name, true);
    }

    const handleDateDeadline = (date, dateString) => {
        setPayload({ ...payload, start_date : dateString[0], due_date: dateString[1]  })
    }


    useEffect(() => {
        dispatch(GETCLIENTPROJECTS())
      },[])

    return (
        <div className='flex flex-col'>
            <PathTrail pathlist={trail} />
            <div className='flex justify-center mt-8 flex-col items-center'>
                <div className='text-2xl font-primary font-semibold'>Create New Task</div>
                <div className='flex flex-col w-1/2 mt-8 space-y-6'>
                    <div className='flex w-full items-center'>
                        <TextInputNormal name="title" className="w-96" size="large" label="Task Name" placeholder="Enter your task name" onChange={onChange} />
                        <PopoverInfo content={<div>Provide a concise and descriptive title for the task. It should clearly convey the main objective or purpose of the task.</div>} />
                    </div>
                    <div className='flex w-full items-center'>
                        <TextInputNormal
                            name="description"
                            children={
                                <TextArea
                                    name="description"
                                    className='w-96 mt-3'
                                    placeholder="Enter task description"
                                    autoSize={{
                                        minRows: 4,
                                        maxRows: 7,
                                    }}
                                    onChange={onChange}
                                />
                            }
                            label="Description"
                            onChange={onChange} />
                        <PopoverInfo content={<div>Give a brief but detailed overview of the task, including the key activities, expectations, and any relevant instructions. Aim for clarity and completeness to ensure all participants understand the task.</div>} />
                    </div>
                    <div className='flex w-full items-center'>
                        <TextInputNormal
                            name="task-dates"
                            children={
                                <div className='flex w-96 justify-between mt-3'>
                                    <DatePicker.RangePicker size="large" className='!w-96 text-secondary' showTime onChange={handleDateDeadline} />
                                    {/* <CustomDatePicker size="large" className='!w-96 text-secondary'  /> */}
                                </div>
                            }
                            label="Task Deadline"
                            onChange={onChange} />
                        <PopoverInfo content={<div>Specify the final date and time by which the task must be completed. Use a clear format (e.g., DD/MM/YYYY, HH ,AM/PM) to avoid confusion.</div>} />
                    </div>
                    <div className='flex w-full items-center'>
                        <TextInputNormal
                            name="sample_media_link"
                            children={
                                <UploadBox className="  !w-96 mt-3" handleChange={ onChangeHandle} />
                            }
                            label="Intro video"
                            onChange={onChange} />
                        <PopoverInfo content={<div>Specify the final date and time by which the task must be completed. Use a clear format (e.g., DD/MM/YYYY, HH ,AM/PM) to avoid confusion.</div>} />
                    </div>
                    <div className='flex flex-col space-y-4 items-start'>
                        <UploadTile name="Video" image="task-video.svg"
                            onChange={(e) => onMediaTypeSelection(masterMediaOptions.Video.options[0]?.value, e, "Video")}
                            isSelected={selMedia.includes("Video")}
                            children={
                                <div className='flex w-[50%] flex-col space-y-3 pl-[5%]'>
                                    {/* <div className='flex items-center space-x-2'>
                                        <Checkbox style={{ color: 'var(--bg-secondary)' }} checked={videoOptions.isLandscapevideo} onChange={(e) => handleVideoOptionChange(e.target.checked, "isLandscapevideo")} />
                                        <div className='text-base'>Encourage the landscape video?</div>
                                    </div> */}
                                    <div className='flex flex-col space-y-3 text-left'>
                                        <div className='text-base'>Start with</div>
                                        <DropDown size="large" options={masterMediaOptions.Video.options} defaultValue={masterMediaOptions.Video.options[0]?.value} onChange={(e) => handleOptionChange(e, true, "Video")} />
                                    </div>
                                </div>
                            }
                        />
                        <UploadTile name="Photo" icon={<CameraFilled />}
                            onChange={(e) => onMediaTypeSelection(masterMediaOptions.Photo.options[0]?.value, e, "Photo")}
                            isSelected={selMedia.includes("Photo")}
                            children={
                                <div className='flex w-[50%] flex-col space-y-3 pl-[5%]'>
                                    <div className='flex flex-col space-y-3 text-left'>
                                        <div className='text-base'>Start with</div>
                                        <DropDown size="large" options={masterMediaOptions.Photo.options} defaultValue={masterMediaOptions.Photo.options[0]?.value} onChange={(e) => handleOptionChange(e, true, "Photo")} />
                                    </div>
                                </div>
                            }
                        />
                        <UploadTile name="Gallery" image="task-photo.svg"
                            onChange={(e) => onMediaTypeSelection(masterMediaOptions.Gallery.options[0]?.value, e, "Gallery")}
                            isSelected={selMedia.includes("Gallery")}
                            children={
                                <div className='flex w-[50%] flex-col space-y-3 pl-[5%]'>
                                    <div className='flex flex-col space-y-3 text-left'>
                                        <div className='text-base'>Start with</div>
                                        <DropDown size="large" options={masterMediaOptions.Gallery.options} defaultValue={masterMediaOptions.Gallery.options[0]?.value} onChange={(e) => handleOptionChange(e, true, "Gallery")} />
                                    </div>
                                </div>
                            }
                        />
                        {/* <UploadTile name="Text" image="task-text.svg" onChange={onMediaTypeSelection} />
                        <UploadTile name="Audio" image="task-audio.svg" onChange={onMediaTypeSelection} /> */}
                        {/* <UploadTile
                            name="Screen Recording"
                            image="task-recording.svg"
                            onChange={onMediaTypeSelection}
                            isSelected={isRecording}
                            children={
                                <div className='flex w-[50%] flex-col space-y-3 pl-[10%]'>
                                    <div className='flex flex-col space-y-3 text-left'>
                                        <div className='text-base'>Start with</div>
                                        <DropDown size="large" options={recordingOptions.options} defaultValue={recordingOptions.options[0]?.value} onChange={(e) => handleRecordingOptionChange(e, "value")} />
                                    </div>
                                </div>
                            }

                        /> */}
                    </div>
                    <div className='flex w-full items-center'>
                        <TextInputNormal
                            children={
                                <TextArea
                                    name="task_instructions"
                                    className='w-96 mt-3'
                                    placeholder="Enter questions separated  by comma"
                                    autoSize={{
                                        minRows: 4,
                                        maxRows: 7,
                                    }}
                                    onChange={onChange}
                                />
                            }
                            label="Show prompts while recording video / audio "
                            onChange={onChange} />
                        <PopoverInfo content={<div>Enable this option to display helpful prompts or reminders on screen while recording.</div>} />
                    </div>
                    <div className='flex items-center w-1/2 justify-between'>
                        <Button className='bg-secondary text-white hover:bg-secondary '>Cancel </Button>
                        <Button type="primary" onClick={onSubmit}>Create Task</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateTask
