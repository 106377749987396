import React, { useEffect, useState } from 'react'
import PrePages from '../layouts/prePages'
import { Button, Form, Input } from 'antd'
import TermAndCondition from 'components/TermAndCondition';
import { VERIFYOTP } from 'state/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function VerifyOTP() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [ otp, setOTP ] = useState("");
  const { userData, isLoggedIn } = useSelector(state => state.user);

  const onFinish = (values) => {
    const payload = {
      "mobile": userData.mobile,
      "code": userData.code,
      ...values
    }

    dispatch(VERIFYOTP(payload))
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onInputChange = (input, value) => {
    // const { value: inputValue } = input.target;
    // console.log("inputValue", input);
    // setOTP(input);
  }

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/dashboard")
    }

  },[isLoggedIn])

  return (
    <PrePages>
      <Form name="basic"
        style={{ width: 430 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        size="large"
        layout="vertical"
      >
        <div className='text-2xl font-medium text-left font-primary mb-4 '>Enter OTP</div>
        {/* <TextInput
          label="OTP"
          name="otp"
          placeholder="OTP"
          autoComplete="off"
          rules={[
            {
              required: true,
              message: 'Please input your OTP!',
            },
          ]}
        /> */}
        <div className='w-full mb-4'>
          <Form.Item name="otp" rules={[
                {
                  required: true,
                  message: 'Please input your OTP!',
                },
              ]} hasFeedback>
            {/* <Input.OTP mask="🔒" onChange={onInputChange} length={6} /> */}
            <Input.OTP
              onChange={onInputChange}
              length={6}
              
            />
          </Form.Item>
        </div>
        <Form.Item>
          <Button className='w-full' type="primary" htmlType="submit">
            Verify
          </Button>
        </Form.Item>
        <TermAndCondition />
      </Form>
    </PrePages>
  )
}

export default VerifyOTP
