import { Breadcrumb } from 'antd'
import React from 'react'

function PathTrail({pathlist = []}) {
    return (
        <div className='flex !text-primary bg-secondary py-4 px-5 rounded-xl'>
            <Breadcrumb
                separator=">"
                items={[
                    {
                        title: 'Home',
                         href: '/dashboard'
                    },
                    ...pathlist
                ]}
            />
        </div>
    )
}

export default PathTrail
