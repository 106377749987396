import { http } from "./httpClient";

export const loginByMobileRequest = (payload) => { 
  return http.post("/loginbyotp", JSON.stringify(payload.payload))
}

export const verifyOTPMobileRequest = (payload) => { 
  return http.post("/verifybyotp", JSON.stringify(payload.payload))
}

export const userLogoutRequest = (payload) => { 
  return http.post("/logout", JSON.stringify(payload.payload))
}

export const getUserDetailsRequest = () => { 
  return http.get("/getprofiledetails")
}

export const getClientProjectRequest = (payload) => { 
  return http.get("/getprojects")
}

export const getProjectTasksRequest = (payload) => { 
  return http.get("/gettasks/" + payload.payload)
}

export const createProjectTaskRequest = (payload) => { 
  return http.post("/addtask",  JSON.stringify(payload.payload))
}

export const getProjectOverviewRequest = (payload) => { 
  const params = new URLSearchParams(payload.payload).toString();
  return http.get("/getoverviewrespondents?" + params)
}

export const getProjectRespondentsRequest = (payload) => { 
  const params = new URLSearchParams(payload.payload).toString();
  return http.get("/getallrespondents?" + params)
}

export default {
  loginByMobileRequest,
  verifyOTPMobileRequest,
  getClientProjectRequest,
  createProjectTaskRequest,
  getProjectTasksRequest,
  getProjectOverviewRequest,
  getProjectRespondentsRequest,
  userLogoutRequest,
  getUserDetailsRequest
}