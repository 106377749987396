import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import Signup from '../pages/signup'
import ForgetPassword from 'pages/forgetPassword'
import VerifyOTP from 'pages/verifyOtp'
import PrivateRoute from './private'
import Dashboard from 'pages/Dashboard'
import { notification } from 'antd'
import { useSelector } from 'react-redux'
import CreateTask from 'pages/Tasks/create'

function Routing(props) {

  const { notify } = useSelector(state => state.user);
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message) => {
    api[type]({
      message: message,
      // description:
      //   'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
    });
  };

  useEffect(() => {
    if (!!notify.message) {
      openNotificationWithIcon(notify.type, notify.message)
    }
   },[notify.timeStamp])


  return (
    <React.Fragment>
      {contextHolder}
      <Routes>
        {/* <Route exact path="/" element={<Landing />} /> */}
        <Route exact path="/" element={<Signup />} />
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/forget-password" element={<ForgetPassword />} />
        <Route exact path="/verify-otp" element={<VerifyOTP />} />
        <Route
          path="dashboard"
          element={<PrivateRoute component={<Dashboard />} />}
        />
        <Route
          path="new-task"
          element={<PrivateRoute component={<CreateTask />} />}
        />
      </Routes>
    </React.Fragment>
  )
}

export default Routing

