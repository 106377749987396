import CustomDatePicker from 'components/DatePicker'
import DropDown from 'components/Dropdown';
import SearchBar from 'components/Search'
import React from 'react'

function Toolbar(props) {

  const onSearch = (value) => {
    console.log("value", value);
  }

  const onChangeHandle = (value) => {
    console.log("value", value);
  }
  return (
    <div className='flex items-center justify-between w-full bg-secondary rounded-lg mt-4 px-7 py-3'>
      <SearchBar onSearch={onSearch} />
      <div className='flex space-x-4'>

        {
          props.isDate && (
            <CustomDatePicker onChangeHandle={onChangeHandle} />
          )
        }
        {
          props.isKeyword && (
            <DropDown className="min-w-32 !text-sm text-left pl-1" placeholder="Keyword" size="large" options={[]} />
          )
        }
        {
          props.isRating && (
            <DropDown className="min-w-25 !text-sm text-left pl-1" placeholder="Rating" size="large" options={[]} />
          )
        }
        {
          props.isRespondent && (
            <DropDown className="min-w-32 !text-sm text-left pl-1" placeholder="Respondent" size="large" options={[]} />
          )
        }
        {
          props.isStatus && (
            <DropDown className="min-w-25 !text-sm text-left pl-1" placeholder="Status" size="large" options={[]} />
          )
        }
        {
          props.isSortStatus && (
            <DropDown className="min-w-25 !text-sm text-left pl-1" placeholder="Sort By Status" size="large" options={[]} />
          )
        }
      </div>
    </div>
  )
}

export default Toolbar
