import React, { useState } from "react";
import { Input } from "antd";

import "./index.css";

const FloatInput = (props) => {
  const [focus, setFocus] = useState(false);
  let { label, value, placeholder, type, required, labelCustomClass = "z-10", children, isPassword = false, className = "", addonBefore = null } = props;

  if (!placeholder) placeholder = label;

  const isOccupied = focus || (value && value.length !== 0);

  const labelClass = isOccupied ? "label as-label" : "label as-placeholder";

  const requiredMark = required ? <span className="text-danger">*</span> : null;

  return (
    <div
      className={"float-label "+ className}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      { isPassword ?
            <Input.Password onChange={props.onChange} type={type} defaultValue={value} />
            : <Input onChange={props.onChange} type={type} defaultValue={value} addonBefore={addonBefore} />
    }
      <label className={labelClass + " " + labelCustomClass}>
        {isOccupied ? label : placeholder} {requiredMark}
      </label>
    </div>
  );
};

export default FloatInput;
