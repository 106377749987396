import { CalendarOutlined, ClockCircleOutlined, MoreOutlined, StarFilled } from '@ant-design/icons';
import Image from 'components/image'
import UniversalMediaCard from 'components/image/UniversalMediaCard';
import moment from 'moment';
import React from 'react'
import { useSelector } from 'react-redux';

function MediaCard({ details }) {
    const today = new Date();
    const { selProject } = useSelector(state => state.user)
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    return (
        <div className='bg-secondary rounded-xl min-h-[440px] w-[280px] cursor-pointer'>
        {/* <div className='bg-secondary rounded-xl min-h-[440px] w-[275px] cursor-pointer hover:transition-shadow shadow-md hover:shadow-theme hover:shadow-sm shadow-black '> */}
            <div className='flex flex-col py-6 space-y-4'>
                <div className='flex flex-col justify-between items-center space-y-3 px-4 '>
                    <div className='flex items-start justify-between space-x-2 w-full'>
                        <div className='text-base text-card font-bold text-left capitalize'>{details?.task_title}</div>
                        <div className="cursor-pointer">
                            <MoreOutlined />
                        </div>
                    </div>
                    <div className='flex flex-col space-y-2 items-start justify-start w-full'>
                        {/* <div className='text-secondary text-xs'> <ClockCircleOutlined /> 02:30 min</div> */}
                        <div className='text-secondary text-xs'>{moment(details.submitted_date).format("DD MMM YYYY")}{" "} | {" "}{moment(details.submitted_date).format("LT")}</div>
                        <div className='flex items-center space-x-2'>
                            <Image url="profile.png" alt="profile" className="w-[25px]" />
                            <div className='text-base text-card'>{details.respondent_name}</div>
                        </div>
                    </div>
                </div>
                <div className='p-4 border-t border-white border-opacity-20'>
                    <UniversalMediaCard url={details.media_link} className="rounded-lg h-[250px] w-full bg-cover" project={selProject} details={details} />
                    <div className='content flex flex-col space-y-2 px-1'>
                        {/* <div className='text-secondary text-xs text-left'> <CalendarOutlined color='var(--text-secondary)' /> &nbsp; {today.toLocaleDateString("en-US", options)}</div> */}
                        <div className='flex items-center text-sm text-left py-2 leading-5'>
                            {details.comment}
                        </div>
                        <div className='flex items-center text-sm text-left space-x-1'>
                            <div>Rating:</div> 
                            <StarFilled style={{color : "#FFD700" }} />  
                            <div className='text-secondary'> {details.rating || 4.5}</div>
                        </div>
                        <div className='flex items-center text-sm text-left leading-3 pt-3 flex-wrap'>
                            {
                                details?.tags && details?.tags.split(",").map(tag => 
                                    <div className='px-3 py-2 mb-2 mr-2 rounded-3xl bg-tertiary capitalize'>{tag}</div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MediaCard
