import React from 'react'

function TermAndCondition() {
  return (
    <div className='text-left text-sm'>
      By proceeding further, you are agreeing to our <a href='#' className='underline text-secondary' >Terms & Conditions</a> and <a href='#' className='underline text-secondary'>Privacy Policy</a>&nbsp;
    </div>
  )
}

export default TermAndCondition
