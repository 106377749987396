import { Skeleton } from 'antd';
import React, { useEffect, useState } from 'react'

function UniversalMediaCard(props) {
    const { url = "", alt = "Dekko", className = "", wrapperclassName = "", details, project } = props;
    const [isImage, setIsImage] = useState(true);
    const [mediaUrl, setMediaUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const checkIfImage = () => {
        if (url.match(/\.(jpg|jpeg|png|gif)$/i)) {
            console.log("Image");
            setIsImage(true)
        } else {
            setIsImage(false)
            console.log("Video");
        }
        setMediaUrl("https://dekko-superadmin.s3.ap-south-1.amazonaws.com/" + project.project_name + "_" + project.uuid + "/task_" + details?.task_uuid + "/" + url);
        // setMediaUrl("https://dekko-superadmin.s3.ap-south-1.amazonaws.com/" + project.project_name + "_" + project.uuid + "/905cb802-7af1-4e49-b50f-78fd9b9eaf4c.MOV");
        setIsLoading(false);
    }

    useEffect(() => {
        if (!!url) {
            checkIfImage();
        }
    }, [url])

    return (
        <div className={`flex justify-center items-center ${wrapperclassName}`}>
            {
                !isLoading ?
                    (isImage ?
                        <img
                            src={mediaUrl}
                            onError={(e) =>
                                (e.target.onerror = null)(
                                    (e.target.src =
                                        "https://picsum.photos/100/100?grayscale")
                                )
                            }
                            className={className}
                        /> :
                        <video muted autoPlay className="!h-full" >
                            <source src={mediaUrl} type="video/mp4"></source>
                        </video>)
                        : 
                        <Skeleton.Image active={true} />
            }
        </div>
    )
}

export default UniversalMediaCard
