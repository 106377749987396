import axios from "axios";
import { getCookie, setCookie } from "utils/session";


//   const { getCookie } = sessionHandle();

  let baseURL = "http://13.200.175.58:9000/v1/client";
//   let baseURL = "/client";

  // if ( process.env.NODE_ENV !== 'development') {
  //   baseURL = "http://localhost:9000/api/services";
  // }

  export const http = axios.create({
    baseURL: baseURL,
    headers: {
      "Content-type": "application/json",
    },
  });


  // // Add a request interceptor
  http.interceptors.request.use(
    (config) => {
      const token = getCookie("_u");
      if (!!token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      config.headers['Content-Type'] = 'application/json';
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  http.interceptors.response.use(
    (response) => {
      console.log("response", response);
      if (response.data.status === 401) {
        console.log("Unauthroized");
      }
      if (response.data.status === 403) {
        console.log("Token Expired");
      }
      return response;
    },
    (error) => {
      console.log("error", error);
      if (error.response && error.response.data) {
        if (error.response.data?.response && error.response.data?.statusCode == 403 ) {
          // console.log("Logout");
          setCookie("_u", "", 0)
        }
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error.data.message);
    }
  );


  export default {
    http
  };
