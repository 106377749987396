import React from "react";
import { Form, Input } from "antd";

import "./index.css";


const TextInputNormal = ({ children = "", size = "", label = "", className = "", labelCustomClass = "", rules = {}, name = "", onChange = {}, value = "", placeholder = "" }) => {
  return (
    <React.Fragment>
      <Form.Item name={name} hasFeedback className="text-left">
        <div className="flex flex-col">
          <label className={"text-white" + " " + labelCustomClass}>
            {label}
          </label>
          {!children ?
            <Input className={"mt-3 " + className} name={name} onChange={onChange} defaultValue={value} placeholder={placeholder} size={size} />
            : children}
        </div>
      </Form.Item>
    </React.Fragment>
  );
};

export default TextInputNormal;
