import React, { useState } from 'react';
import { Divider, Radio, Table } from 'antd';
const columns = [
  {
    title: 'Name',
    dataIndex: 'full_name',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Initials',
    dataIndex: 'initials',
  },
  {
    title: 'UUID',
    dataIndex: 'respondent_uuid',
  },
];
const data = [
  {
    key: '1',
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park',
  },
  {
    key: '2',
    name: 'Jim Green',
    age: 42,
    address: 'London No. 1 Lake Park',
  },
  {
    key: '3',
    name: 'Joe Black',
    age: 32,
    address: 'Sydney No. 1 Lake Park',
  },
  {
    key: '4',
    name: 'Disabled User',
    age: 99,
    address: 'Sydney No. 1 Lake Park',
  },
];

// rowSelection object indicates the need for row selection
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === 'Disabled User',
    // Column configuration not to be checked
    name: record.name,
  }),
};
const CustomTable = (props) => {
  return (
    <div className='py-4 text-primary'>
      <Table
        // rowSelection={{
        //   type: 'checkbox',
        //   ...rowSelection,
        // }}
        columns={columns}
        dataSource={props?.tableData}
      />
    </div>
  );
};
export default CustomTable;