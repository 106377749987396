import { Checkbox } from 'antd'
import Image from 'components/image'
import React, { useState } from 'react'

function UploadTile({ image, name, onChange = {}, isSelected = false, children = <></>, icon =null }) {

  const handleChange = (e, name) => {
    // console.log(`checked = ${e.target.checked}`);
    onChange(e.target.checked, name)
  }

  return (
    <React.Fragment>
      <div className='flex bg-secondary text-base rounded-lg w-96 p-4 justify-between '>
        <div className='flex items-center space-x-3'>
          {
            !icon ?
            <Image url={image} alt={name} className="w-6 h-6" />
            : icon
          }
          <div className='text-base'>{name}</div>
        </div>
        <div>
          <Checkbox style={{ color: 'var(--bg-secondary)' }} onChange={(e) => handleChange(e, name )} />
        </div>
      </div>
      {
        isSelected && 
        children
      }
    </React.Fragment>

  )
}

export default UploadTile
