import { Form, Select } from 'antd'
import React from 'react'

function DropDown(props) {

  return (
    <React.Fragment>
        <Select
          theme={'dark'}
          size={props.size ? props.size : "middle"}
          className={props.className}
          defaultValue={props.defaultValue}
          placeholder={props.placeholder}
          onChange={props.onChange}
          options={props.options}
          {...props}
        />
    </React.Fragment>
  )
}

export default DropDown
